<template>
  <div
    class="iot-login-page"
    :style=" `background-image:url(${constant.SYSTEM_IMG}bg.gif);background-repeat:no-repeat;`"
  >
   <el-alert
   v-if="titleVis"
   class="title"
    :title="title"
    type="warning"
    >
  </el-alert>
    <div class="iot-login-container public-shadow">
      <div class="iot-form public-shadow" v-show="activeModule == 'login'">
        <!-- 登录 -->
        <el-image
          class="iot-title-image"
          :src="`${constant.OSS_URL}${constant.PLATFORM_IMG}${platformLogo}`"
        ></el-image>
        <h2>{{ platformName }}</h2>
        <el-form
          style="width: 66%; margin: 5% 17%"
          :model="loginForm"
          :rules="fRules"
        >
          <el-form-item>
            <el-input v-model="loginForm.username" placeholder="用户名">
              <i slot="prefix" class="iot-form-icon iconfont">&#xe60d;</i>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="loginForm.password"
              placeholder="密码"
              type="password"
             @keyup.enter.native="login()"
            >
              <i slot="prefix" class="iot-form-icon iconfont">&#xe635;</i>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-checkbox style="float: right" v-model="isRem">
              记住密码
            </el-checkbox>
          </el-form-item>
          <el-form-item>
            <el-button
              @click.stop="login"
              
              style="width: 100%"
              type="primary"
              plain
            >
              登 录
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
              @click.stop="getPassword"
              style="width: 100%"
              type="primary"
              plain
            >
              游客账号
            </el-button>
          </el-form-item>
        </el-form>
        <div style="width: 66%; margin: 5% 17%; font-weight: bold">
          <el-link
            type="primary"
            @click.native="activeModule = 'activation'"
            :underline="false"
            >激活帐号</el-link
          >
          <el-link
            style="float: right"
            type="primary"
            @click.native="activeModule = 'forget';forgetForm.newPassword=''"
            :underline="false"
            >忘记密码</el-link
          >
        </div>
      </div>
      <div
        class="iot-form public-shadow iot-forget loginInput"
        v-show="activeModule == 'forget'"
      >
        <!-- 忘记密码 -->
        <h2 style="margin-top: 15%">忘记密码</h2>
        <el-form
        label-width="100px"
          style="width: 66%; margin: 5% 17%"
          :model="forgetForm"
          :rules="fRules"
          ref="fRef"
        >
          <el-form-item  label="用户名">
            <el-input v-model="forgetForm.username"  placeholder="用户名">
              <i slot="prefix" class="iot-form-icon iconfont">&#xe60d;</i>
            </el-input>
          </el-form-item>
          <el-form-item prop="newPassword" label="输入新密码">
            <el-input
              v-model="forgetForm.newPassword"
              placeholder="输入新密码"
              type="password"
            >
              <i slot="prefix" class="iot-form-icon iconfont">&#xe635;</i>
            </el-input>
          </el-form-item>
          <el-form-item prop="repeatPassword"  label="确认新密码">
            <el-input
              v-model="forgetForm.repeatPassword"
              placeholder="确认新密码"
              type="password"
            >
              <i slot="prefix" class="iot-form-icon iconfont">&#xe635;</i>
            </el-input>
          </el-form-item>
          <el-form-item prop="code" label="验证码">
            <el-input v-model="forgetForm.code" :maxlength="6" placeholder="输入验证码">
              <i slot="prefix" class="iot-form-icon iconfont">&#xe63d;</i>
              <el-button
                type="text"
                slot="suffix"
                @click="accountGet(forgetForm.username, 'forget')"
                :disabled="fCode.indexOf('秒') > 0 ? true : false"
                >{{ fCode }}</el-button
              >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              @click="forgetSub()"
              style="width: 100%"
              type="primary"
              plain
            >
              提交
            </el-button>
          </el-form-item>
        </el-form>
        <div style="width: 100%; text-align: center">
          <el-link :underline="false" type="info" style="text-align: center">
            返回 </el-link
          >&nbsp;<el-link
            @click.native="activeModule = 'login'"
            :underline="false"
            type="primary"
            >登&nbsp;录</el-link
          >
        </div>
      </div>
      <!-- 激活 -->
      <div
        class="iot-form public-shadow iot-forget"
        v-show="activeModule == 'activation'"
      >
        <!-- 激活账号 -->
        <h2 style="margin-top: 15%">激活账号</h2>
        <el-form
          style="width: 66%; margin: 5% 17%"
          :model="activationForm"
          :rules="aRules"
          ref="aRef"
        >
          <el-form-item prop="username">
            <el-input v-model="activationForm.username" placeholder="用户名">
              <i slot="prefix" class="iot-form-icon iconfont">&#xe60d;</i>
            </el-input>
          </el-form-item>
          <el-form-item prop="code">
            <el-input v-model="activationForm.code" :maxlength="6" placeholder="输入验证码">
              <i slot="prefix" class="iot-form-icon iconfont">&#xe63d;</i>
              <el-button
                type="text"
                slot="suffix"
                :disabled="count > 0 ? true : false"
                @click="accountGet(activationForm.username, 'activation')"
                >{{ aCode }}</el-button
              >
            </el-input>
          </el-form-item>
          <el-button
            @click="activationSub()"
            style="width: 100%"
            type="primary"
            plain
          >
            提交
          </el-button>
        </el-form>
        <div style="width: 100%; text-align: center">
          <el-link :underline="false" type="info" style="text-align: center">
            已激活，返回 </el-link
          >&nbsp;<el-link
            @click.native="activeModule = 'login'"
            :underline="false"
            type="primary"
            >登&nbsp;录</el-link
          >
        </div>
      </div>
      <!-- <el-image
        class="iot-bg-image"
        :src="`${constant.SYSTEM_IMG}img.png`"
      ></el-image> -->
    </div>
    <el-dialog
    top="3%"
      :visible.sync="dialogVisible"
      width="310"
      :destroy-on-close="true"
    >
      <slide-verify
        :l="42"
        :r="10"
        :w="310"
        :h="155"
        @success="onSuccess"
        @fail="onFail"
        :slider-text="text"
      ></slide-verify>
    </el-dialog>
    <footer
      style="position: absolute; bottom: 0px; text-align: center; width: 100%"
    >
      <div style="width: 300px; margin: 0 auto; padding: 0; font-size: 10px">
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33011002014350"
          style="
            display: inline-block;
            text-decoration: none;
            height: 20px;
            line-height: 20px;
          "
        >
          <img :src="src" style="float: left" />
          <p
            style="
              float: left;
              height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 5px;
              color: #ffffff;
            "
          >
            浙公网安备 33011002014350号
          </p></a
        >
        <p
          style="
            height: 20px;
            line-height: 20px;
            margin: 0px 0px 0px 5px;
            color: #ffffff;
          "
        >
          ©2021-2030 cniot.vip 版权所有。 浙ICP备18033850号-2
        </p>
      </div>
    </footer>
  </div>
</template>
<script>
import { OSS_URL, SYSTEM_IMG, PLATFORM_IMG } from "../util/constant";
import reg from "../util/regular";
let that;
export default {
  name: "Login",
  props: {
    msg: String,
  },
  data() {
    return {
      count:0,
      titleVis:false,
      title:"",
      src: require("../assets/备案图标.png"),
      dialogVisible: false,
      text: "验证码",
      activeModule: "login",
      constant: { OSS_URL, SYSTEM_IMG, PLATFORM_IMG },
      loginForm: {
        username: "",
        password: "",
      },
      forgetForm: {
        username: "",
        newPassword: "",
        repeatPassword: "",
        code: "",
      },
      activationForm: {
        username: "",
        code: "",
      },
      isRem: false,
      path: "",
      platformLogo: "",
      platformName: "",
      fCode: "获取验证码",
      aCode: "获取验证码",
      aRules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            pattern: reg.accountR,
            message: "限6-18个字符，支持中英文、数字、减号或下划线",
          },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      fRules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            pattern: reg.accountR,
            message: "限6-18个字符，支持中英文、数字、减号或下划线",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            pattern: reg.pswR,
            message: "限6-18个字符，支持中英文、数字、减号或下划线",
          },
          {
            validator: (rule, value, callback) => {
              if (that.forgetForm.password != that.forgetForm.rePassword) {
                callback(new Error("两次密码不一致"));
              }
              callback();
            },
            trigger: "blur",
          },
        ],
        rePassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            pattern: reg.pswR,
            message: "限6-18个字符，支持中英文、数字、减号或下划线",
          },
          {
            validator: (rule, value, callback) => {
              if (that.forgetForm.password != that.forgetForm.rePassword) {
                callback(new Error("两次密码不一致"));
              }
              callback();
            },
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      codeType: "",
    };
  },
  methods: {
    handlerKeyChange: function ($ev) {
      if (that.activeModule == "login") {
        if ($ev.keyCode === 13) {
          that.login();
        }
      }
    },
    getPassword: function () {
      that.loginForm = {
        username: "guests",
        password: "111111",
      };

      that.login();
    },
    onSuccess() {
      that.dialogVisible = false;
       if (that.count>0) {
         that.$message({
          type: "error",
          message: "请稍后在发送！",
        });
        return;
      }
      if (that.codeType == "forget") {
        that.accountCode(that.forgetForm.username);
      } else if (that.codeType == "activation") {
        that.accountCode(that.activationForm.username);
      }
    },
    onFail() {
      that.$message({
        type: "danger",
        message: "操作失败，请重试！",
      });
    },
    login() {
      if (that.loginForm.username.trim() == '' || that.loginForm.password.trim() == '') {
        that.$message.info('用户名或者密码不能为空')
        return
      }
      that.$api.auth.login(that.loginForm).then((response) => {
        if (response.code === 200) {
          that.$store.commit("setToken",'Bearer ' +response.data.token);
          that.$store.commit("setTokenId", response.data.tokenId);
          that.$store.commit("setUserId", response.data.userId);
          that.$store.commit("setPermissionArr", response.data.permissions);
          window.localStorage.setItem("permissions", response.data.permissions);
          window.localStorage.setItem("cId", response.data.userId)
          that.$store.commit("setUsername", that.loginForm.username);
          that.$store.commit("setRank", response.data.rank);
          // that.$store.commit("setopenNumber", true);
          that.$store.commit("setName", response.data.fullName);
          window.localStorage.setItem("token",'Bearer ' +response.data.token);
          that.$router.push("/home");
          that.$store.commit("setActiveMenu", "/home");
          if (that.isRem) {
            window.localStorage.setItem(
              "loginInfo",
              JSON.stringify(that.loginForm)
            );
            window.localStorage.setItem("isRem", that.isRem);
          }else{
            window.localStorage.setItem(
              "loginInfo",
             ""
            );
            window.localStorage.setItem("isRem", that.isRem);
          }
          // that.check();
        }
      });
    },
    // check() {
    //   that.$api.pub.checkToken().then((response) => {
    //     response;
    //   });
    // },
    mobileGet() {
      if (reg.mobileR.test(that.forgetForm.mobile)) {
        that.dialogVisible = true;
        that.codeType = "forget";
      }
    },
    accountGet(data, codeType) {
      if (reg.accountR.test(data)) {
        // that.dialogVisible = true;
        that.codeType = codeType;
        that.onSuccess();
      }
    },
    phoneCode() {
      that.$api.auth.mobileGetCode(that.forgetForm.mobile).then(() => {
        that.$message({
          type: "success",
          message: "验证码已发送",
        });

          that.count = 120;
        let int = setInterval(() => {
          if (that.count > 0) {
            that.fCode = that.count-- + "秒";
          } else {
            clearInterval(int);
            that.fCode = "获取验证码";
          }
        }, 1000);
      });
    },
    accountCode(data) {
      that.$api.auth.accountGetCode(data).then((res) => {
        if (res.code == 200) {
          that.$message({
          type: "success",
          message: "验证码已发送",
        });
        }
       that.count = 120;
        let int = setInterval(() => {
          if (that.count > 0) {
            that.codeType == "forget"
              ? (that.fCode = that.count-- + "秒")
              : (that.aCode = that.count-- + "秒");
          } else {
            clearInterval(int);
            that.codeType == "forget"
              ? (that.fCode = "获取验证码")
              : (that.aCode = "获取验证码");
          }
        }, 1000);
      });
    },
    activationSub() {
      that.$api.auth.activation(that.activationForm).then((response) => {
        if (response.code == 200) {
          that.activeModule = "login";
          that.$refs.aRef.resetFields();
          that.$message({
            type: "success",
            message: response.msg,
          });
        }
      });
    },
    forgetSub() {
      that.$api.auth.changePassword(that.forgetForm).then((response) => {
        if (response.code == 200) {
          that.activeModule = "login";
          that.$refs.fRef.resetFields();
          that.$message({
            type: "success",
            message: response.msg,
          });
        }
      });
    },
  },
  beforeMount() {
    that = this;
    that.path = that.$route.params.path;
  },
  mounted() {
    that.titleVis=false;
    that.title=""
    //  window.devicePixelRatio 浏览器缩放比率
    if (window.devicePixelRatio!=1) {
      that.title="当前浏览器缩放比率不为100%，可能会影响界面显示，请调整后刷新页面。"
       that.titleVis=true;
    }
    if (window.screen.availWidth!=1920||window.screen.height!=1080) {
      that.title="当前浏览器分辨率不为1920*1080，可能会影响界面显示，请调整后刷新页面。"
       that.titleVis=true;
    }

    if (!that.$store.state.token) {
       that.$api.auth.getPlatform(that.path).then((response) => {
      if (response.code == 200) {
        that.platformLogo = response.data.platformLogo;
        that.platformName = response.data.platformName;
      } 
    });
    }
   
    window.localStorage.setItem("path", that.path);
    that.$store.commit("setPath", that.path);

    if (window.localStorage.getItem("isRem") === "true") {
      that.isRem = JSON.parse(window.localStorage.getItem("isRem"));
      that.loginForm = JSON.parse(window.localStorage.getItem("loginInfo"));
    }
    // window.addEventListener("keyup", that.handlerKeyChange, false);
    window.localStorage.setItem("cId","");
    window.localStorage.setItem("selectGroup","");
  },
};
</script>

<style scoped>
@import "../util/common.css";

.iot-login-page {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: auto;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.iot-login-container {
  position: absolute;
  right: 8.5%;
  top: 12.5%;
  height: 75%;
  width: 30%;

  /* background-color: rgba(255, 255, 255, 0.3);
  background-image: url("https://oss.cniot.fun/iot-system/sys-program/img.png");
  background-repeat: no-repeat;
  background-size: 60% 100%;
  background-position: 15% 0; */
}
.iot-form {
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}
.iot-form >>> .el-form-item {
  margin-bottom: 25px;
}
h2 {
  font-size: 22px;
  text-align: center;
  margin-block-start: 20px;
  margin-block-end: 20px;
}
.iot-title-image {
  width: 120px;
  height: 120px;
  border-radius: 5px;
  margin-left: calc(50% - 60px);
  margin-top: 5%;
}
.iot-bg-image {
  height: 100%;
  width: 60%;
  padding-left: 10%;
}
.iot-form-icon {
  margin-left: 5px;
}
/* .iot-forget {
  padding-top: 5%;
} */
/* .iot-form {
  margin-left: 55%;
  position: absolute;
} */
.iot-login-page >>> .el-dialog {
  width: 350px;
}
.title{
  
  position: relative;
  top: 40px;
  left: 50%;
  transform: translate(-50%);
  width: 83%;
  height: 40px;
  box-sizing: border-box;
  padding:0 100px;
  color:  #f56c6c;
  text-align: center;
  display: flex;
  justify-content: center;
}

::v-deep .title .el-alert__title{
  font-size: 24px;
}
</style>
